import { render, staticRenderFns } from "./returnModal.vue?vue&type=template&id=667f507a&"
import script from "./returnModal.vue?vue&type=script&lang=js&"
export * from "./returnModal.vue?vue&type=script&lang=js&"
import style0 from "./returnModal.vue?vue&type=style&index=0&id=667f507a&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('667f507a')) {
      api.createRecord('667f507a', component.options)
    } else {
      api.reload('667f507a', component.options)
    }
    module.hot.accept("./returnModal.vue?vue&type=template&id=667f507a&", function () {
      api.rerender('667f507a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/remessa-boleto/tabs/Boletos/returnModal.vue"
export default component.exports