<template>
  <div id="employee-new-modal">
    <vs-popup class="popup90" :title="title" :active.sync="localShow">
      <div class="vx-col w-full mt-0  mb-2 flex items-center">
        <vs-checkbox v-model="selectAllCheckBox" :disabled="!preProcessingResponse.boletos.length" icon-pack="feather" :icon="selectAllIcon" class="select-all-chexkbox">Selecionar Tudo</vs-checkbox>
      </div>

      <vs-table noDataText="Nenhum boleto a ser processado." :data="preProcessingResponse.boletos" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
        <template slot="thead">
          <vs-th style="font-size: 11px;"><span>#</span></vs-th>
          <vs-th style="font-size: 11px;"><span>Status</span></vs-th>
          <vs-th style="font-size: 11px;"><span>Pagador</span></vs-th>
          <vs-th style="font-size: 11px;"><span>Processamento</span></vs-th>
          <vs-th style="font-size: 11px;"><span>Vencimento</span></vs-th>
          <vs-th style="font-size: 11px;"><span>Nosso nº</span></vs-th>
          <vs-th style="font-size: 11px;"><span>Situação fin.</span></vs-th>
          <vs-th style="font-size: 11px;"><span>Ocorrência</span></vs-th>
          <vs-th style="font-size: 11px;"><span>Dt. crédito</span></vs-th>
          <vs-th style="font-size: 11px;"><span>Valor</span></vs-th>
          <vs-th style="font-size: 11px;"><span>Valor recebido</span></vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr v-for="(boleto, i) in data" :key="i" :class="{'text-danger': data.transaction_cfc}" style="font-size: 12px; opacity: 1 !important">
            <vs-td>
              <vs-checkbox v-model="selectedBoletos" :vs-value="boleto" class="vs-checkbox-small ml-0 mr-1"></vs-checkbox>
            </vs-td>
            <vs-td>
              {{boleto.ocorrencia === '1' ? 'Gerado' : (boleto.ocorrencia === '06' ? 'Liquidado' :  'Status desconhecido') }}
            </vs-td>
            <vs-td>
              {{ boleto.transaction_cfc ? boleto.transaction_cfc.student.name : 'sem correspondência financeira.' }}
            </vs-td>
            <vs-td>
              {{ boleto.data_processamento | dateTime }}
            </vs-td>
            <vs-td>
              {{ boleto.data_vencimento | dateTime }}
            </vs-td>
            <vs-td>
              {{ boleto.nosso_numero }}
            </vs-td>
            <vs-td>
              {{ boleto.transaction_cfc ? (boleto.transaction_cfc.situation == 'PAID' ? 'PAGO' : 'PENDENTE') : 'Não se aplica.' }}
            </vs-td>
            <vs-td>
              {{ boleto.ocorrenciaDescricao }}
            </vs-td>
            <vs-td>
              {{ boleto.dataCredito ? formatDateTime(boleto.data_credito) : 'Aberto' }}
            </vs-td>
            <vs-td>
              {{ boleto.valor ? boleto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }}
            </vs-td>
            <vs-td>
              {{ boleto.valorRecebido ? boleto.valorRecebido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="processReturn" :disabled="!selectedBoletos.length">Processar retorno</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {

  components: {
    vSelect
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    preProcessingResponse: {
      type: Object,
      required: false
    }
  },

  computed: {
    selectAllCheckBox: {
      get () {
        return this.selectedBoletos.length
      },
      set (value) {
        const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
        if (value) {
          this.selectedBoletos = this.preProcessingResponse.boletos
          this.selectedBoletos.map(e => {
            if (!e.payday) e.payday = `${YYYY}-${MM}-${DD}`
            e.value_pay = e.value_parcel
          })
        } else {
          this.selectedBoletos = []
        }
      }
    },
    selectAllIcon () {
      return this.selectedBoletos.length === this.preProcessingResponse.boletos.length ? 'icon-check' : 'icon-minus'
    }
  },

  data () {
    return {
      localShow: false,
      selectedBoletos: []
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
      }
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    formatDateTime (dateTime) {
      if (!dateTime) return ''
      const [YYYY, MM, DD] = (dateTime.slice(0, 10)).split('-')
      const [H, m, s] = (dateTime.slice(11, 19)).split(':')
      return `${DD}/${MM}/${YYYY} ${H}:${m}:${s}`
    },

    async processReturn () {
      try {
        this.$vs.loading()
        await this.$http.post(`${process.env.VUE_APP_API_URL}/return-processing`, this.selectedBoletos)
        this.$nextTick(() => { this.$vs.loading.close() })
        this.action()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Processados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.localShow = false
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup90 .vs-popup {
    width: 90% !important;
  }

  .vue_select_drop_size_200 .vs__dropdown-menu {
    max-height: 200px;
  }
</style>
