var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "vx-col w-full mt-0 mb-2 flex items-center" },
            [
              _c(
                "vs-checkbox",
                {
                  staticClass: "select-all-chexkbox",
                  attrs: {
                    disabled: !_vm.preProcessingResponse.boletos.length,
                    "icon-pack": "feather",
                    icon: _vm.selectAllIcon,
                  },
                  model: {
                    value: _vm.selectAllCheckBox,
                    callback: function ($$v) {
                      _vm.selectAllCheckBox = $$v
                    },
                    expression: "selectAllCheckBox",
                  },
                },
                [_vm._v("Selecionar Tudo")]
              ),
            ],
            1
          ),
          _c(
            "vs-table",
            {
              staticClass: "bordered",
              staticStyle: { overflow: "-webkit-paged-y" },
              attrs: {
                noDataText: "Nenhum boleto a ser processado.",
                data: _vm.preProcessingResponse.boletos,
                hoverFlat: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (boleto, i) {
                      return _c(
                        "vs-tr",
                        {
                          key: i,
                          class: { "text-danger": data.transaction_cfc },
                          staticStyle: {
                            "font-size": "12px",
                            opacity: "1 !important",
                          },
                        },
                        [
                          _c(
                            "vs-td",
                            [
                              _c("vs-checkbox", {
                                staticClass: "vs-checkbox-small ml-0 mr-1",
                                attrs: { "vs-value": boleto },
                                model: {
                                  value: _vm.selectedBoletos,
                                  callback: function ($$v) {
                                    _vm.selectedBoletos = $$v
                                  },
                                  expression: "selectedBoletos",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  boleto.ocorrencia === "1"
                                    ? "Gerado"
                                    : boleto.ocorrencia === "06"
                                    ? "Liquidado"
                                    : "Status desconhecido"
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  boleto.transaction_cfc
                                    ? boleto.transaction_cfc.student.name
                                    : "sem correspondência financeira."
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("dateTime")(boleto.data_processamento)
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("dateTime")(boleto.data_vencimento)
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(boleto.nosso_numero) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  boleto.transaction_cfc
                                    ? boleto.transaction_cfc.situation == "PAID"
                                      ? "PAGO"
                                      : "PENDENTE"
                                    : "Não se aplica."
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(boleto.ocorrenciaDescricao) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  boleto.dataCredito
                                    ? _vm.formatDateTime(boleto.data_credito)
                                    : "Aberto"
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  boleto.valor
                                    ? boleto.valor.toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      })
                                    : "R$ 0,00"
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  boleto.valorRecebido
                                    ? boleto.valorRecebido.toLocaleString(
                                        "pt-BR",
                                        { style: "currency", currency: "BRL" }
                                      )
                                    : "R$ 0,00"
                                ) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("#")]),
                  ]),
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("Status")]),
                  ]),
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("Pagador")]),
                  ]),
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("Processamento")]),
                  ]),
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("Vencimento")]),
                  ]),
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("Nosso nº")]),
                  ]),
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("Situação fin.")]),
                  ]),
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("Ocorrência")]),
                  ]),
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("Dt. crédito")]),
                  ]),
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("Valor")]),
                  ]),
                  _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                    _c("span", [_vm._v("Valor recebido")]),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.selectedBoletos.length },
                      on: { click: _vm.processReturn },
                    },
                    [_vm._v("Processar retorno")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }